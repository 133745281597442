/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function Home({ authenticated, baseUrl }) {
  const [latestDrive, setLatestDrive] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [available, setAvailable] = useState(false);

  useEffect(() => {
    setLoaded(false);
    fetch(`${baseUrl}/api/campaign/`).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          console.log(data)
          if (data.docs.length === 0) {
            setAvailable(false)
            setLoaded(true)
          } else {
            setLatestDrive(data.docs[data.docs.length - 1]);
            setAvailable(true)
            setLoaded(true);
          }
        });
      } else {
        setLoaded(true);
        alert("Invalid drive id.");
      }
    });
  }, [baseUrl]);

  if (loaded) {
    return (
      <div>
        <h1>
          Welcome to Drivathon <i class="fas fa-leaf"></i>
        </h1>
        <p className="text-muted">
          A place to stop typing and start campaigning.
        </p>
        <Link to="/drives">Get Started</Link>
        <hr />
        <h3>History of Drivathon</h3>
        <p>
          This started as a silly startup idea came up by 2 7th graders, but got so behind its development that they eventually built the startup into the site that it is.
        </p>
        <hr />
        <h3>Latest Drives</h3>
        <p>
          Check all the latest drives which curatively handpicked for you. Save the drive or campaign to join and participate in or like, comment and share the campaign page to other people to show you devotion.
        </p>
        {(() => {
          if (available) {
            return (
              <div class="card w-50">
                <div className="card-header">
                  <h5>Latest</h5>
                </div>
                <img
                  src={latestDrive.img_url}
                  class="card-img-top img-fluid"
                  alt="tweet-img"
                />
                <div class="card-body">
                  <h5>{latestDrive.title}</h5>
                  <p>{latestDrive.desc.slice(0, 160)+"..."}</p>
                  <p>Posted by, {latestDrive.added_by}</p>
                  <div className="btn-group">
                    <a
                      className="btn btn-primary"
                      href={`/drive/${latestDrive._id}`}
                    >
                      Details
                    </a>
                    <a
                      className="btn btn-secondary"
                      href={latestDrive.map_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Map
                    </a>
                  </div>
                </div>
                <div className="card-footer">
                  <p class="text-muted">posted on {new Date((latestDrive.date_created)).toUTCString()}</p>
                </div>
              </div>
            );
          } else {
            return <p>No Drives Yet</p>;
          }
        })()}
      </div>
    );
  } else {
    return <p>Loading...</p>;
  }
}

export default Home;
