function About() {
  return (
    <div>
      <h3>Our History</h3>
      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iste similique
        earum quidem repudiandae illum, quas incidunt consectetur aut. Minima
        nemo amet molestias. Possimus, similique. Officia quidem nam esse alias,
        id rerum quo magnam iste consequuntur ab dignissimos laborum in
        praesentium officiis quibusdam ipsum culpa a voluptatibus, minus veniam
        asperiores iusto laboriosam sed magni! Labore corporis distinctio
        aspernatur cum. Aliquam, porro.
      </p>
      <h3>Our Mission</h3>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatibus
        eum repudiandae commodi voluptate modi veritatis ea atque. Ratione,
        aliquam velit iure quis voluptate omnis sed quo architecto, repellendus,
        odit itaque reiciendis atque. Consectetur, necessitatibus? Illum odio,
        deserunt nulla sapiente consequuntur magnam nisi minus! Cum, ex
        distinctio eligendi id aperiam eum quos facilis labore, dicta eius
        dignissimos animi necessitatibus consequuntur temporibus mollitia?
        Corporis ad doloremque quaerat architecto sint officia rem ullam facere
        voluptatem harum.
      </p>
      <h3>Our Ideology</h3>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit amet,
        repellendus id accusantium laboriosam dicta harum. Corporis architecto
        non voluptatibus alias ipsa veritatis, eum aspernatur. Sapiente facilis
        et distinctio quisquam. Tempora cupiditate nobis dicta incidunt harum,
        ex, tempore minima doloribus repellat enim necessitatibus, consequatur
        fuga dolores! Odit aspernatur esse modi tempora ea fuga, dolorum
        deserunt ab commodi nulla non sed facilis quisquam saepe magni
        voluptatibus provident, delectus quasi eveniet. Enim corporis quaerat
        obcaecati repudiandae qui pariatur cumque ut suscipit perferendis.
      </p>
    </div>
  );
}

export default About;
