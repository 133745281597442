import React from "react";

function Error(props) {
    return ( 
        <div>
            <img src="https://img.pixers.pics/pho_wat(s3:700/FO/38/98/90/35/700_FO38989035_c62ebd71719979d510e86d327bda4045.jpg,700,700,cms:2018/10/5bd1b6b8d04b8_220x50-watermark.png,over,480,650,jpg)/stickers-furry-blue-monster.jpg.jpg" alt="404error" className="img-fluid"/>
            <h3>No Need to Worry</h3>
            <h5>Its Just a 404 page.</h5>
            <p>Reason: {props.error}</p>
            <a href="/">Go Back To Safety</a>
        </div>
    );
}

export default Error;