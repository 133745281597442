import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Profile({ authenticated, baseUrl, token }) {
  const [user, setUser] = useState({});
  const [savedDrive, setSavedDrives] = useState([]);
  const [ownDrives, setOwnDrives] = useState([]);
  const [loaded, setLoaded] = useState(true);

  const [profile, setProfile] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    async function loadData() {
      setLoaded(false);
      const profileResponse = await fetch(`${baseUrl}/api/user/profile`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (profileResponse.ok) {
        let userData = await profileResponse.json();
        setUser(userData);
        const savedDriveResponse = await fetch(`${baseUrl}/api/user/drive`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (savedDriveResponse.ok) {
          let savedDriveData = await savedDriveResponse.json();
          setSavedDrives(savedDriveData);
          let ownDrivesResponse = await fetch(`${baseUrl}/api/user/drive/own`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          if (ownDrivesResponse.ok) {
            let ownDrivesData = await ownDrivesResponse.json();
            setOwnDrives(ownDrivesData);
            setLoaded(true);
          } else {
            setLoaded(true);
            alert("Error fetching user data");
          }
        } else {
          setLoaded(true);
          alert("Error fetching user data");
        }
      } else {
        setLoaded(true);
        alert("Error fetching user data");
      }
    }
    loadData();
  }, [baseUrl, token]);

  const changePassword = async () => {
    setLoaded(false);
    let changePasswordResponse = await fetch(`${baseUrl}/api/user/password`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "PUT",
      body: JSON.stringify({
        password: password,
      }),
    });
    if (changePasswordResponse.ok) {
      let userData = { ...user };
      let json = await changePasswordResponse.json();
      userData.password = json.password;
      setUser(userData);
      setLoaded(true);
    } else {
      alert("Error changing password");
      setLoaded(true);
    }
  };

  const changeProfilePhoto = async () => {
    setLoaded(false);
    let changePasswordResponse = await fetch(`${baseUrl}/api/user/profilepic`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "PUT",
      body: JSON.stringify({
        profile_pic: profile,
      }),
    });
    if (changePasswordResponse.ok) {
      let userData = { ...user };
      let json = await changePasswordResponse.json();
      userData.profile_pic = json.profile_pic;
      setUser(userData);
      setLoaded(true);
    } else {
      alert("Error changing password");
      setLoaded(true);
    }
  };

  const deleteSavedDrive = async (id) => {
    setLoaded(false);
    const response = await fetch(`${baseUrl}/api/user/drive/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "DELETE",
    });
    if (response.ok) {
      window.location.reload();
      setLoaded(true);
    } else {
      alert("Error changing password");
      setLoaded(true);
    }
  };

  const deleteOwnDrive = async (id) => {
    setLoaded(false);
    const response = await fetch(`${baseUrl}/api/campaign/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "DELETE",
    });
    if (response.ok) {
      let data = await response.json()
      await deleteSavedDrive(data._id)
    } else {
      alert("Error changing password");
      setLoaded(true);
    }
  }

  if (loaded) {
    return (
      <div>
        <h3>User Profile</h3>
        <p>General User Settings can be twicked here.</p>
        <hr />
        <span className="d-flex align-items-center justofy-content-between mb-2">
          <p>Username: {user.username}</p>
        </span>
        <span className="d-flex align-items-center justofy-content-between mb-2">
          <p>Email Address: {user.email}</p>
        </span>
        <span className="d-flex align-items-center justify-content-between mb-2">
          <p>Password: {user.password}</p>
          <button
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#changePassword"
          >
            Change Password
          </button>
        </span>
        <span className="d-flex align-items-center justify-content-between mb-2">
          <p>Profile Photo: </p>
          <button
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#changeProfilePhoto"
          >
            Change Profile Photo
          </button>
        </span>
        {(() => {
          if (user.profile_pic === "") {
            return <span>No Profile Photo</span>;
          } else {
            return <img src={user.profile_pic} alt="" />;
          }
        })()}
        <br />
        <br />
        <h3>Saved Drives</h3>
        <p>All Saved Drives can be viewed and managed here.</p>
        <hr />
        {(() => {
          if (savedDrive.length === 0) {
            return <p>No Drives Saved Yet</p>;
          } else {
            return savedDrive.map((drive) => (
              <div key={drive._id}>
                <div class="card w-25">
                  <img
                    src={drive.img_url}
                    class="card-img-top img-fluid"
                    alt="tweet-img"
                  />
                  <div class="card-body">
                    <h5>{drive.title}</h5>
                    <p>{drive.desc}</p>
                    <p>Posted by, {drive.added_by}</p>
                    <div className="btn-group">
                      <a
                        className="btn btn-primary"
                        href={`/drive/${drive._id}`}
                      >
                        Details
                      </a>
                      <button
                        className="btn btn-secondary"
                        onClick={() => deleteSavedDrive(drive._id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p class="text-muted">posted on {drive.date_created}</p>
                  </div>
                </div>
                <br />
              </div>
            ));
          }
        })()}
        <h3>Own Drives</h3>
        <p>Drives that are created by you can be viewed and manages here.</p>
        <hr />
        {(() => {
          if (ownDrives.length === 0) {
            return <p>No Drives Created Yet</p>;
          } else {
            return ownDrives.map((drive) => (
              <div key={drive._id}>
                <div class="card w-25">
                  <img
                    src={drive.img_url}
                    class="card-img-top img-fluid"
                    alt="tweet-img"
                  />
                  <div class="card-body">
                    <h5>{drive.title}</h5>
                    <p>{drive.desc}</p>
                    <p>Posted by, {drive.added_by}</p>
                    <div className="btn-group">
                      <a
                        className="btn btn-primary"
                        href={`/drive/${drive._id}`}
                      >
                        Details
                      </a>
                      <button className="btn btn-secondary" onClick={() => deleteOwnDrive(drive._id)}>Delete</button>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p class="text-muted">posted on {drive.date_created}</p>
                  </div>
                </div>
                <br />
              </div>
            ));
          }
        })()}
        <div
          class="modal fade"
          id="changePassword"
          tabindex="-1"
          aria-labelledby="changePasswordLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Change Password
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="form-group">
                  <div className="form-floating">
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="commentText">Password</label>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => changePassword()}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="changeProfilePhoto"
          tabindex="-1"
          aria-labelledby="changeProfilePhotoLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Change Password
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="form-group">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={profile}
                      onChange={(e) => setProfile(e.target.value)}
                    />
                    <label htmlFor="commentText">Profile Photo</label>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => changeProfilePhoto()}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <p>Loading</p>;
  }
}

export default Profile;
