/* eslint-disable no-unused-vars */
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

function Drive({ authenticated, baseUrl, token }) {
  const { id } = useParams();

  const [drive, setDrive] = useState({});
  const [comment, setComment] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [driveSaved, setDriveSaved] = useState(false);

  useEffect(() => {
    setLoaded(false);
    fetch(`${baseUrl}/api/campaign/${id}`).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          fetch(`${baseUrl}/api/user/drive_id`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }).then((res) => {
            if (res.ok) {
              res.json().then((data) => {
                if (Array.isArray(data)) {
                  let present = data.find((data) => data === id);
                  if (present) {
                    setDriveSaved(true);
                  } else {
                    setDriveSaved(false);
                  }
                }
              });
            } else {
              setLoaded(true);
              alert("Errpr");
            }
          });
          setDrive(data);
          setLoaded(true);
        });
      } else {
        setLoaded(true);
        alert("Invalid drive id.");
      }
    });
  }, [baseUrl, id, token]);

  let getDerivedStateFromError = (error) => {
    console.log(error);
  };

  let commentPost = () => {
    setLoaded(false);
    fetch(`${baseUrl}/api/campaign/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "PUT",
      body: JSON.stringify({
        content: comment,
      }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          if (data.error) {
            alert(data.error);
            window.location.reload();
          } else {
            window.location.reload();
            setLoaded(true);
          }
        });
      } else {
        alert("Error adding comment");
        window.location.reload();
      }
    });
  };

  let upVoteDrive = () => {
    setLoaded(false);
    fetch(`${baseUrl}/api/campaign/upvote/${id}`, { method: "PUT" }).then(
      (res) => {
        if (res.ok) {
          res.json().then((data) => {
            setDrive(data);
            setLoaded(true);
          });
        } else {
          alert("Invalid drive id.");
        }
      }
    );
  };

  let downVoteDrive = () => {
    setLoaded(false);
    fetch(`${baseUrl}/api/campaign/downvote/${id}`, { method: "PUT" }).then(
      (res) => {
        if (res.ok) {
          res.json().then((data) => {
            setDrive(data);
            setLoaded(true);
          });
        } else {
          alert("Invalid drive id.");
        }
      }
    );
  };

  let saveDrive = () => {
    setLoaded(false);
    fetch(`${baseUrl}/api/user/drive/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          console.log(data);
          setLoaded(true);
          window.location.reload();
        });
      } else {
        alert("Invalid drive id.");
      }
    });
  };

  if (loaded) {
    return (
      <div>
        <h1>{drive.title}</h1>
        <p>{drive.desc}</p>
        <div className="d-flex align-items-center justify-content-between">
          <span>Votes: {drive.votes}</span>
          <div className="btn-group">
            <button className="btn btn-success" onClick={() => upVoteDrive()}>
              <i class="far fa-thumbs-up"></i>
            </button>
            <button className="btn btn-primary" onClick={() => downVoteDrive()}>
              <i class="far fa-thumbs-down"></i>
            </button>
          </div>
        </div>
        <hr />
        <img src={drive.img_url} alt="drive-img" className="img-fluid" />
        <br />
        <br />
        <div className="btn-group">
          <a
            href={drive.map_url}
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
          >
            Map
          </a>
          {(() => {
            if (authenticated) {
              if (!driveSaved) {
                return (
                  <button
                    className="btn btn-danger"
                    onClick={() => saveDrive()}
                  >
                    Save
                  </button>
                );
              } else {
                return (
                  <button className="btn btn-danger" disabled>
                    Saved
                  </button>
                );
              }
            }
          })()}
        </div>
        <div className="mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <h3>Comments</h3>
            {(() => {
              if (authenticated) {
                return (
                  <button
                    className="btn btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#postModal"
                  >
                    <i class="fas fa-plus-square"></i>
                  </button>
                );
              } else {
                return (
                  <p className="text-muted">
                    Login or Signup inorder to leave a comment
                  </p>
                );
              }
            })()}
          </div>
          <hr />
          <ul className="list-group">
            {(() => {
              if (drive.comments.length !== 0) {
                return <Comments drive={drive} />;
              } else {
                return <p>No Comments Yet. Login and Add One Now.</p>;
              }
            })()}
          </ul>
        </div>
        <div
          class="modal fade"
          id="postModal"
          tabindex="-1"
          aria-labelledby="logoutModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Comment
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="form-group">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <label htmlFor="commentText">Comment</label>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => commentPost()}
                >
                  Post
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <p>Loading...</p>;
  }
}

function Comments({ drive }) {
  return (
    <div>
      {drive.comments.map((comment) => (
        <li
          className="list-group-item d-flex align-items-center justify-content-between"
          key={comment._id}
        >
          {comment.content}{" "}
          <span className="text-muted">by, {comment.added_by}</span>
        </li>
      ))}
    </div>
  );
}

export default Drive;
