import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

function New({ baseUrl, token }) {

    const [title, setTitle] = useState("")
    const [desc, setDesc] = useState("")
    const [imgUrl, setImgUrl] = useState("")
    const [mapUrl, setMapUrl] = useState("")
    const [loaded, setLoaded] = useState(true)

    const navigate = useNavigate()

    useEffect(() => {
        setLoaded(true)
        setTitle("")
        setDesc("")
        setImgUrl("")
        setMapUrl("")
    }, [])

    const reset = () => {
        setTitle("")
        setDesc("")
        setImgUrl("")
        setMapUrl("")
    }

    const submit = () => {
        setLoaded(false)
        fetch(`${baseUrl}/api/campaign`, 
        {
            method:"POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${token}`
            },
            body:JSON.stringify({
                title:title,
                desc:desc,
                img_url:imgUrl,
                map_url:mapUrl
            })
        })
        .then(res => {
            if(res.ok){
                navigate("/drives")
            }
            else{
                alert("Error Adding Drive")
                setLoaded(true)
            }
        })
    }

    if(loaded){
        return ( 
            <div>
                <h3>Add a new drive or campaign</h3><hr />
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Title</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" value={title} onChange={e => setTitle(e.target.value)}/>
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Image Url</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" value={imgUrl} onChange={e => setImgUrl(e.target.value)}/>
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Map Url</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" value={mapUrl} onChange={e => setMapUrl(e.target.value)}/>
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" value={desc} onChange={e => setDesc(e.target.value)}/>
                </div>
                <button className="btn btn-primary me-2" onClick={() => submit()}>Submit</button>
                <button className="btn btn-danger" onClick={() => reset()}>Reset</button>
            </div>
        );
    }
    else{
        return(
            <p>Loading</p>
        )
    }
}

export default New;