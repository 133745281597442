/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Home from "./pages/Home"
import Drives from "./pages/Drives"
import New from "./pages/New"
import Drive from "./pages/Drive"
import About from "./pages/About"
import Profile from "./pages/Profile"
import Error from "./pages/Error"

function App() {

  const baseURL = "https://drivathon.onrender.com"

  const [authenticated, setAuthenticated] = useState(false)
  const [authToken, setAuthToken] = useState("")
  const [loaded, setLoaded] = useState(true)

  const [loginUsername, setLoginUsername] = useState("")
  const [loginPassword, setLoginPassword] = useState("")
  const [signupUsername, setSignupUsername] = useState("")
  const [signupEmail, setSignupEmail] = useState("")
  const [signupPassword, setSignupPassword] = useState("")


  useEffect(()=>{
    let token = window.localStorage.getItem("token")
    token !== null ? setAuthenticated(true) : setAuthenticated(false)
    token !== null ? setAuthToken(token) : setAuthToken("")
  }, [])

  const loginUser = () => {
    setLoaded(false)
    fetch(`${baseURL}/api/user/login`, 
    { 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method:"POST",
      body:JSON.stringify(
        {
          username:loginUsername,
          password:loginPassword
        }
      )
    })
    .then(res => {
      if(res.ok){
        res.json()
        .then(data => {
          if(data.err){
            alert(data.err)
            setLoaded(true)
          }
          else{
            window.localStorage.setItem("token", data.access_token)
            setAuthenticated(true)
            setAuthToken(data.access_token)
            setLoaded(true)
          }
        })
      }
      else{
        alert("Error Logging User. Please try again")
        setLoaded(true)
      }
    })
  }

  const signupUser = () => {
    setLoaded(false)
    fetch(`${baseURL}/api/user/signup`, 
    { 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method:"POST",
      body:JSON.stringify(
        {
          username:signupUsername,
          password:signupPassword,
          email:signupEmail
        }
      )
    })
    .then(res => {
      if(res.ok){
        res.json()
        .then(data => {
          if(data.err){
            alert(data.err)
            setLoaded(true)
          }
          else{
            setLoaded(false)
            fetch(`${baseURL}/api/user/login`, 
            { 
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              method:"POST",
              body:JSON.stringify(
                {
                  username:data.username,
                  password:data.password
                }
              )
            })
            .then(res => {
              if(res.ok){
                res.json()
                .then(data => {
                  if(data.err){
                    alert(data.err)
                    setLoaded(true)
                  }
                  else{
                    window.localStorage.setItem("token", data.access_token)
                    setAuthenticated(true)
                    setAuthToken(data.access_token)
                    setLoaded(true)
                  }
                })
              }
              else{
                alert("Error Loggining User. Please try again")
                setLoaded(true)
              }
            })
          }
        })
      }
      else{
        alert("Error Loggining User. Please try again")
        setLoaded(true)
      }
    })
  }
  
  const logoutUser = () => {
    window.localStorage.clear()
    setAuthenticated(false)
    setAuthToken("")
  }

  if(authenticated){
    return (
      <BrowserRouter>
        <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
          <div class="container-fluid">
            <Link to="/" className="navbar-brand">
              Drivathon <i class="fas fa-leaf"/>
            </Link>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page">
                    Home
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/drives" className="nav-link">
                    Drives
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/new" className="nav-link">
                    New
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/about" className="nav-link">
                    About
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/profile" className="nav-link">
                    Profile
                  </Link>
                </li>
              </ul>
              <div class="d-flex">
                <button class="btn btn-success" type="submit" data-bs-toggle="modal" data-bs-target="#logoutModal">
                  Logout
                </button>
              </div>
            </div>
          </div>
        </nav>
        <section className="p-5 my-4">
          <div className="container">
            { (()=>{
              if(loaded){
                  return(
                    <Routes>
                      <Route exact path="/" element={<Home authenticated={true} baseUrl={baseURL}/>}/>
                      <Route exact path="/drives" element={<Drives baseUrl={baseURL} authenticated={true}/>}/>
                      <Route exact path="/drive/:id" element={<Drive baseUrl={baseURL} authenticated={true} token={authToken}/>}/>
                      <Route exact path="/new" element={<New baseUrl={baseURL} authenticated={true} token={authToken}/>}/>
                      <Route exact path="/about" element={<About/>}/>
                      <Route exact path="/profile" element={<Profile baseUrl={baseURL} authenticated={true} token={authToken}/>}/>
                      <Route exact path="*" element={<Error error="Page Not Found"/>}/>
                    </Routes>
                  )
              }
              else{
                return <p>Loding</p>
              }
            })() }
          </div>
        </section>
        <div class="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Logout</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Are You Sure You Want To Logout?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={() => logoutUser()}>Logout</button>
              </div>
            </div>
          </div>
        </div>
      </BrowserRouter>
    );
  }else{
    return (
      <BrowserRouter>
        <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
          <div class="container-fluid">
            <Link to="/" className="navbar-brand">
              Drivathon<i class="fas fa-leaf"></i>
            </Link>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page">
                    Home
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/drives" className="nav-link">
                    Drives
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/about" className="nav-link">
                    About
                  </Link>
                </li>
              </ul>
              <div class="d-flex btn-group">
                <button className="btn btn-success" data-bs-toggle="modal" data-bs-target="#signupModal">
                  Signup
                </button>
                <button class="btn btn-success" type="submit" data-bs-toggle="modal" data-bs-target="#loginModal">
                  Login
                </button>
              </div>
            </div>
          </div>
        </nav>
        <section className="p-5 my-4">
          <div className="container">
            { (()=>{
              if(loaded){
                return(
                  <Routes>
                    <Route exact path="/" element={<Home authenticated={false} baseUrl={baseURL}/>}></Route>
                    <Route exact path="/drives" element={<Drives baseUrl={baseURL} authenticated={false}/>}></Route>
                    <Route exact path="/drive/:id" element={<Drive authenticated={false} baseUrl={baseURL}/>}></Route>
                    <Route exact path="/about" element={<About/>}></Route>
                    <Route exact path="*" element={<Error error="Page Not Found"/>}></Route>
                  </Routes>
                )
              }
              else{
                return <p>Loding</p>
              }
            })() }
          </div>
        </section>
        <div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Login</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
              <div class="form-group">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput" value={loginUsername} onChange={e => setLoginUsername(e.target.value)}/>
                  <label for="floatingInput">Username</label>
                </div>
                <div class="form-floating">
                  <input type="password" class="form-control" id="floatingPassword" value={loginPassword} onChange={e => setLoginPassword(e.target.value)}/>
                  <label for="floatingPassword">Password</label>
                </div>
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={() => loginUser()}>Login</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="signupModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Signup</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
              <div class="form-group">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput" value={signupUsername} onChange={e => setSignupUsername(e.target.value)}/>
                  <label for="floatingInput">Username</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="email" class="form-control" id="floatingInput" value={signupEmail} onChange={e => setSignupEmail(e.target.value)}/>
                  <label for="floatingInput">Email</label>
                </div>
                <div class="form-floating">
                  <input type="password" class="form-control" id="floatingPassword" value={signupPassword} onChange={e => setSignupPassword(e.target.value)}/>
                  <label for="floatingPassword">Password</label>
                </div>
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={() => signupUser()}>Signup</button>
              </div>
            </div>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
