/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

function Drives({ baseUrl, authenticated }) {
  const [drives, setDrives] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}/api/campaign`).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
            setDrives(data.docs)
        });
      } else {
        alert("Error Fetching Drives");
      }
    });
  }, [baseUrl]);

  if(drives.length === 0){
    return (
        <div>
          <h3>All Drives Or Campaigns To Join</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. A, ex. Qui,
            velit odit cupiditate, quod commodi dolore provident ipsum maxime
            excepturi error distinctio.
          </p><hr />
          <p>No Drives or Comments Yet</p>
        </div>
    );
  }
  else{
    return (
        <div>
          <h3>All Drives Or Campaigns To Join</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. A, ex. Qui,
            velit odit cupiditate, quod commodi dolore provident ipsum maxime
            excepturi error distinctio.
          </p><hr />
          {drives.map(drive => {
              return(
                <div>
                  <div class="card w-50" key={drive._id}>
                  <img
                    src={drive.img_url}
                    class="card-img-top img-fluid"
                    alt="tweet-img"
                  />
                  <div class="card-body">
                    <h5>{drive.title}</h5>
                    <p>{drive.desc.slice(0, 160)+"..."}</p>
                    <p>Posted by, {drive.added_by}</p>
                    <div className="btn-group">
                      <a className="btn btn-primary" href={`/drive/${drive._id}`}>
                        Details
                      </a>
                      <a className="btn btn-secondary" href={drive.map_url} target="_blank" rel="noreferrer">
                        Map
                      </a>
                    </div>
                  </div>
                  <div className="card-footer">
                    <p class="text-muted">posted on {new Date((drive.date_created)).toUTCString()}</p>
                  </div>
                </div><br/>
                </div>  
              )
        })}
        </div>
      );
  }
}

export default Drives;
